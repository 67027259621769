import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { Container } from '@liftfoils/components'
import { SectionCardsWithDescriptionProps } from '@liftfoils/models'
import { CardWithDescription } from './CardWithDescription'

const Wrap = styled('div', {
  $py: 'L',
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$9',
  justifyItems: 'center',
  variants: {
    columns: {
      two: {
        '@md': {
          gridTemplateColumns: '1fr 1fr',
        },
      },
      three: {
        '@md': {
          gridTemplateColumns: '1fr 1fr',
        },
        '@lg': {
          gridTemplateColumns: '1fr 1fr 1fr',
        },
      },
    },
  },
})

export const SectionCardsWithDescriptions: FC<
  SectionCardsWithDescriptionProps
> = ({ cards }) => {
  if (!cards) {
    return null
  }

  return (
    <Container>
      <Wrap columns={cards.length === 3 ? 'three' : 'two'}>
        {cards.map((card, i) => (
          <CardWithDescription
            key={i}
            background={card.background}
            description={card.description}
            isPortrait={cards.length === 3}
          />
        ))}
      </Wrap>
    </Container>
  )
}
