import { styled } from '@liftfoils/styles'
import { MediaFieldType, PortableTextBlocks } from '@liftfoils/models'
import { PortableText, Media, MediaPlaceholder } from '@liftfoils/components'

export type CardWithDescriptionProps = {
  background?: MediaFieldType
  description?: PortableTextBlocks
  isPortrait?: boolean
}
const Card = styled('div', {
  width: '100%',
})

const MediaWrap = styled('div', {
  borderRadius: '$r1',
  overflow: 'hidden',
  transform: 'translateZ(0)',
})

const Description = styled('div', {
  mt: '$8',
  lift_font: 'heading03',
  color: '$gray300',
  '.highlighted': {
    color: '$gray500',
  },
  'p + p': {
    mt: '$8',
  },
})

export const CardWithDescription = ({
  background,
  isPortrait,
  description,
}: CardWithDescriptionProps) => {
  const hardcropRatio = isPortrait ? 'portrait2' : 'landscape4'
  return (
    <Card>
      <MediaWrap>
        <MediaPlaceholder ratio={hardcropRatio}>
          <Media
            {...background}
            layout="fill"
            hardcropRatio={{
              '856': 'portrait2',
              '1440': hardcropRatio,
              '1920': hardcropRatio,
              '2880': hardcropRatio,
            }}
            sizes={
              isPortrait
                ? '(min-width:1300px) 32vw, (min-width:1000px) 50vw, 100vw'
                : '(min-width:1000px) 50vw, 100vw'
            }
            alt={background?.alt ?? ''}
          />
        </MediaPlaceholder>
      </MediaWrap>
      {description && (
        <Description>
          <PortableText value={description} />
        </Description>
      )}
    </Card>
  )
}
